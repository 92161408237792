import * as yup from 'yup'

import { UserDetails } from './types'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'

const defaultUser: UserDetails = {
   ID: '',
   DisplayCompanyName: false,
   Company: '',
   FirstName: '',
   LastName: '',
   Phone: '',
   Address1: '',
   Address2: '',
   Address3: '',
   City: '',
   State: '',
   ZipCode: '',
   Country: null,
   Email: '',
   Industry: null,
   EnterpriseUser: false,
   EnterpriseUsersAllowed: 0,
   EntepriseExpiryDate: null,
   Role: null,
   SetPassword: null,
   EmailConfirmed: false,
}

const userRoleOptions: Option[] = [
   {
      value: 'Company',
      label: 'Company',
   },
   {
      value: 'Admin',
      label: 'Admin',
   },
   {
      value: 'Employee',
      label: 'Employee',
   },
   {
      value: 'User',
      label: 'User',
   },
]

const requiredErrorMsg = 'This field is required'

const userSchema = yup.object().shape({
   ID: yup.string(),
   FirstName: yup.string().required(requiredErrorMsg),
   LastName: yup.string().required(requiredErrorMsg),
   Role: yup.string().required(requiredErrorMsg),
   Email: yup.string().email().required(requiredErrorMsg),
})

export { defaultUser, userRoleOptions, requiredErrorMsg, userSchema }
