import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { PATH } from '../../constants/global'
import { useAPI } from '..'
import { UseUsers, User, UserId, UserSearchOptions } from './types'
import { useEffect, useState } from 'react'
import { UserDetails } from '../../components/views/users/types'

export default (): UseUsers => {
   const request = useAPI()
   const [userSearchOptions, setUserSearchOptions] =
      useState<UserSearchOptions | null>(null)
   const [passwordResetId, setPasswordResetId] = useState<UserId | null>(null)

   const {
      data,
      isLoading,
      refetch,
      isRefetching: isRefetchingUserList,
   } = useQuery(
      'users-list',
      (): Promise<AxiosResponse> => getUsersFromAPI(userSearchOptions),
      { enabled: !!userSearchOptions }
   )

   async function getUsersFromAPI(options: {}) {
      try {
         return await request.post(PATH.USERS.LIST, options)
      } catch (error) {
         console.log(error)
         return error
      }
   }

   const {
      data: passwordData,
      isLoading: isLoadingUserPassword,
      refetch: refetchPassword,
      isRefetching: isRefetchingUserPassword,
   } = useQuery(
      'user-password-generate',
      (): Promise<AxiosResponse> => getUserPasswordFromAPI(passwordResetId),
      { enabled: !!passwordResetId }
   )

   async function getUserPasswordFromAPI(userId: { id: string }) {
      if (userId?.id) {
         try {
            return await request.post(PATH.USERS.PASSWORD_GENERATE, userId)
         } catch (error) {
            console.log(error)
            return error
         }
      }
   }

   const getUserPassword = (userId: string | null) =>
      setPasswordResetId({ id: userId })

   const {
      isLoading: isUpdateLoading,
      mutate: mutateUser,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
   } = useMutation(updateUserInAPI)

   async function updateUserInAPI(userDetails: UserDetails) {
      return await request.post(PATH.USERS.UPDATE, userDetails)
   }

   const updateUserDetails = (userDetails: UserDetails) =>
      mutateUser(userDetails)

   useEffect(() => {
      if (userSearchOptions) {
         refetch()
      }
   }, [userSearchOptions])

   useEffect(() => {
      if (passwordResetId !== null) {
         refetchPassword()
      }
   }, [passwordResetId])

   return {
      isLoading,
      userList: data?.data.Users || ([] as User[]),
      setUserSearchOptions,
      isRefetchingUserList,
      userListTotalCount: data?.data?.TotalCount,
      userGeneratedPassword: passwordData?.data.password ?? '',
      getUserPassword,
      isRefetchingUserPassword,
      isLoadingUserPassword,
      updateUserDetails,
      isUpdateLoading: isUpdateLoading,
      isUpdateSuccess,
      isUpdateError,
   }
}
