import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
   CrudActionButtons,
   IconButton,
   LoadingOverlay,
   PageHeader,
   Search,
   SnackBar,
   TrueOrFalse,
} from '../../common'
import { Actions, PAGINATION } from '../../../constants/tables'
import useUsers from '../../../hooks/useUsers/useUsers'
import { TableWrapper } from '../../common/table/TableWrapper'
import { TableColumnCellWrapper } from '../../common/table/tableColumnCellWrapper'
import GeneratePasswordModal from './generatePasswordModal'
import { formatUsDate, removeMinYear } from '../../../common/utils/functions'
import { GeneratePasswordDetails, UserDetails, UserValues } from './types'
import { SnackBarState } from '../../common/snackBar/types'
import { closedSnackbarState } from '../../common/snackBar/snackBar'
import { Severity } from '../../../types'
import EditUserModal from './editUserModal'
import { defaultUser, userSchema } from './constants'
import { useAPI, useForm } from '../../../hooks'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'
import { PATH } from '../../../constants/global'
import { format } from 'date-fns'

const Users = () => {
   const request = useAPI()
   const [isPasswordModalOpen, setIsPasswordModalOpen] =
      useState<boolean>(false)
   const [passwordModalDetatils, setPasswordModalDetatils] =
      useState<GeneratePasswordDetails>({})
   const [copyButtonText, setCopyButtonText] = useState<string>('Copy')
   const passwordRef = useRef<any>(null)
   const [searchCriteria, setSearchCriteria] = useState<string>('')
   const [isSearchMode, setIsSearchMode] = useState<boolean>(false)
   const [pageSized, setPageSized] = useState<number>(PAGINATION.pageSized)
   const [isPageReset, setIsPageReset] = useState<boolean>(false)
   const [isPaginationReset, setIsPaginationReset] = useState<boolean>(false)
   const [snackbar, setSnackbar] = useState<SnackBarState>(closedSnackbarState)
   const [isEditUserModalOpen, setIsEditUserModalOpen] =
      useState<boolean>(false)
   const [selectedUser, setSelectedUser] = useState<UserDetails>(defaultUser)
   const [loadingUser, setLoadingUser] = useState<boolean>(false)

   const {
      userList,
      isLoading,
      setUserSearchOptions,
      isRefetchingUserList,
      userListTotalCount,
      getUserPassword,
      userGeneratedPassword,
      isLoadingUserPassword,
      isRefetchingUserPassword,
      updateUserDetails,
      isUpdateSuccess,
      isUpdateLoading,
      isUpdateError,
   } = useUsers()

   useEffect(() => {
      // initial call for users
      refreshUsers()
   }, [])

   useEffect(() => {
      if (
         userGeneratedPassword &&
         !isLoadingUserPassword &&
         !isRefetchingUserPassword
      ) {
         setIsPasswordModalOpen(true)
         setSnackbar({
            message: `A new password was generated for ${
               passwordModalDetatils?.Name ?? 'the user'
            }.`,
            severity: Severity.SUCCESS,
         })
      }
   }, [isLoadingUserPassword, isRefetchingUserPassword, userGeneratedPassword])

   useEffect(() => {
      if (isUpdateSuccess) {
         handleEditModalClose()
         setSnackbar({
            message: 'The user details were updated.',
            severity: Severity.SUCCESS,
         })
         refreshUsers()
      }
   }, [isUpdateSuccess])

   useEffect(() => {
      if (isUpdateError) {
         handleEditModalClose()
         setSnackbar({
            message: 'Error updating user details.',
            severity: Severity.ERROR,
         })
      }
   }, [isUpdateError])

   const handleSearchQuery = (query?: string, skip?: number, take?: number) => {
      setIsPaginationReset(true)
      if (query) {
         setSearchCriteria(query)
      }
      handleGetUsers(query, skip, take)
   }

   const handleGetUsers = (query?: string, skip?: number, take?: number) => {
      setUserSearchOptions({
         skip,
         take,
         query,
      })
   }

   const handleDisplayPassword = (rowValues: UserValues) => {
      const { CTA, FirstName, LastName, Company, Email } = rowValues
      getUserPassword(CTA)
      setPasswordModalDetatils({
         Name: `${FirstName} ${LastName}`,
         Company: Company,
         Email: Email,
      })
   }

   const handleClosePasswordModal = () => {
      getUserPassword(null)
      setIsPasswordModalOpen(false)
   }

   const handleCopyText = () => {
      const passwordInput = passwordRef.current
      setCopyButtonText('Copied')
      setTimeout(() => {
         setCopyButtonText('Copy')
      }, 2000)

      if (passwordInput) {
         passwordInput.select()
         navigator.clipboard.writeText(passwordInput.value)
      }
   }

   const { onSubmit, validationErrors, onReset } = useForm(
      userSchema,
      (values) => {
         // typo exists in API
         const EntepriseExpiryDate = format(
            new Date(selectedUser.EntepriseExpiryDate),
            'd MMM yyyy'
         )
         const EnterpriseUsersAllowed = values?.EnterpriseUsersAllowed
            ? Number(values?.EnterpriseUsersAllowed)
            : selectedUser.EnterpriseUsersAllowed
         const SetPassword =
            values.SetPassword?.length > 0 ? values.SetPassword : null
         const updatedUser = {
            ...values,
            DisplayCompanyName: selectedUser.DisplayCompanyName,
            EnterpriseUser: selectedUser.EnterpriseUser,
            EmailConfirmed: selectedUser.EmailConfirmed,
            EnterpriseUsersAllowed: EnterpriseUsersAllowed,
            EntepriseExpiryDate: EntepriseExpiryDate,
            SetPassword: SetPassword,
         } as UserDetails
         updateUserDetails(updatedUser)
      }
   )

   const handleDropdownChange = (fieldName: string, option: Option) => {
      setSelectedUser((prevState) => ({
         ...prevState,
         [fieldName]: option.value,
      }))
   }

   const handleToggleChange = (name: string, checked: boolean) => {
      setSelectedUser((prevState) => ({
         ...prevState,
         [name]: checked,
      }))
   }

   const handleDateChange = (name: string, date: Date) => {
      setSelectedUser((prevState) => ({
         ...prevState,
         [name]: date,
      }))
   }

   const getUserDetails = async (userID: string) => {
      setLoadingUser(true)
      try {
         const { data } = await request.post(
            `${PATH.USERS.GET}?userID=${userID}`
         )
         setSelectedUser(data)
         setIsEditUserModalOpen(true)
         setLoadingUser(false)
      } catch (error) {
         setIsEditUserModalOpen(false)
         setLoadingUser(false)
         setSnackbar({
            message: 'Error fetching user details.',
            severity: Severity.ERROR,
         })
         return error
      }
   }

   const handleEditModalClose = () => {
      setIsEditUserModalOpen(false)
      onReset()
      setSelectedUser(defaultUser)
   }

   const refreshUsers = () => {
      setIsPageReset(true)
      setIsPaginationReset(true)
      setUserSearchOptions({
         skip: PAGINATION.skip,
         take: pageSized,
         query: searchCriteria,
      })
   }

   const showActionForm = async (action: Actions, id: string) => {
      switch (action) {
         case Actions.Edit:
            await getUserDetails(id)
            break
         default:
            return
      }
   }

   const columns = useMemo(
      () => [
         {
            Header: 'Actions',
            accessor: 'ID',
            id: 'CTA',
            width: 150,
            minWidth: 60,
            align: 'center',
            Cell: (cell) => (
               <>
                  <CrudActionButtons
                     cell={cell}
                     action={showActionForm}
                     editAction
                  />
                  <IconButton
                     id="logout" // icon name
                     tooltip="Generate password"
                     action={() => handleDisplayPassword(cell.row.values)}
                  />
               </>
            ),
         },
         {
            Header: 'Role',
            accessor: 'Role',
            id: 'Role',
            width: 100,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Role ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'First Name',
            accessor: 'FirstName',
            id: 'FirstName',
            width: 250,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.FirstName ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Last Name',
            accessor: 'LastName',
            id: 'LastName',
            width: 250,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.LastName ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Email',
            accessor: 'Email',
            id: 'Email',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Email ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Display Company Name',
            accessor: 'DisplayCompanyName',
            id: 'DisplayCompanyName',
            width: 200,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => (
               <TrueOrFalse status={row.original.DisplayCompanyName} />
            ),
         },
         {
            Header: 'Company',
            accessor: 'Company',
            id: 'Company',
            width: 300,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Company ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Connected Company Name',
            accessor: 'ConnectedCompanyName',
            id: 'ConnectedCompanyName',
            width: 300,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.ConnectedCompanyName ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Industry',
            accessor: 'Industry',
            id: 'Industry',
            width: 300,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Industry ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Address line 1',
            accessor: 'Address1',
            id: 'Address1',
            width: 300,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Address1 ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Address line 2',
            accessor: 'Address2',
            id: 'Address2',
            width: 300,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Address2 ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Address line 3',
            accessor: 'Address3',
            id: 'Address3',
            width: 300,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Address3 ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'City',
            accessor: 'City',
            id: 'City',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.City ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'State/Province',
            accessor: 'State',
            id: 'State',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.State ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Postal Code',
            accessor: 'ZipCode',
            id: 'ZipCode',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.ZipCode ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Country',
            accessor: 'Country',
            id: 'Country',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Country ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Phone',
            accessor: 'Phone',
            id: 'Phone',
            width: 200,
            minWidth: 20,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Phone ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Enterprise User',
            accessor: 'EnterpriseUser',
            id: 'EnterpriseUser',
            width: 150,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => (
               <TrueOrFalse status={row.original.EnterpriseUser} />
            ),
         },
         {
            Header: 'Enterprise User Start Date',
            align: 'center',
            accessor: (row: { EntepriseStartDate: string }) => {
               return removeMinYear(formatUsDate(row.EntepriseStartDate) ?? '-')
            },
            id: 'EntepriseStartDate', // this is spelt incorrectly in the back-end
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Enterprise Expiry Date',
            align: 'center',
            accessor: (row: { EntepriseExpiryDate: string }) => {
               return removeMinYear(
                  formatUsDate(row.EntepriseExpiryDate) ?? '-'
               )
            },
            id: 'EntepriseExpiryDate',
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Enterprise Users Allowed',
            accessor: 'EnterpriseUsersAllowed',
            id: 'EnterpriseUsersAllowed',
            width: 200,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.EnterpriseUsersAllowed ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Date Created',
            align: 'center',
            accessor: (row: { DateCreated: string }) => {
               return removeMinYear(formatUsDate(row.DateCreated) ?? '-')
            },
            id: 'DateCreated',
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Date Modified',
            align: 'center',
            accessor: (row: { DateModified: string }) => {
               return removeMinYear(formatUsDate(row.DateModified) ?? '-')
            },
            id: 'DateModified',
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Last Login Date',
            align: 'center',
            accessor: (row: { LastLoginDate: string }) => {
               return removeMinYear(formatUsDate(row.LastLoginDate) ?? '-')
            },
            id: 'LastLoginDate',
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Verified User',
            accessor: 'EmailConfirmed',
            id: 'EmailConfirmed',
            width: 150,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => (
               <TrueOrFalse status={row.original.EmailConfirmed} />
            ),
         },
      ],
      [userList]
   )

   const loading =
      isLoading ||
      isRefetchingUserList ||
      isLoadingUserPassword ||
      isRefetchingUserPassword ||
      isUpdateLoading ||
      loadingUser

   return (
      <>
         <PageHeader title="Users" />
         {loading && <LoadingOverlay />}
         <SnackBar
            message={snackbar.message}
            open={
               snackbar.message !== '' && snackbar.severity !== Severity.INFO
            }
            severity={snackbar.severity}
            resetMessageStateHandler={() => setSnackbar(closedSnackbarState)}
         />
         <Search
            handleQuery={handleSearchQuery}
            searchMode={isSearchMode}
            setSearchMode={setIsSearchMode}
            pageSized={pageSized}
            placeHolder={' Users'}
            setSearchCriteria={setSearchCriteria}
            isPageSearchReset={isPageReset}
            setIsPageSearchReset={setIsPageReset}
         />
         {!isLoading && (
            <TableWrapper
               columns={columns}
               data={userList as []}
               getItems={handleGetUsers}
               skipItems={PAGINATION.skip}
               takeItems={PAGINATION.take}
               totalCount={userListTotalCount}
               extraClassName=""
               isLoading={loading}
               isPageReset={isPaginationReset}
               setIsPageReset={setIsPaginationReset}
               searchCriteria={searchCriteria}
               searchMode
               setItemId={(id) => null}
               setPageSized={setPageSized}
               setSearchMode={() => null}
               hideResultsTitle
            />
         )}
         {isPasswordModalOpen && (
            <GeneratePasswordModal
               userGeneratedPassword={userGeneratedPassword}
               isPasswordModalOpen={isPasswordModalOpen}
               handleClosePasswordModal={handleClosePasswordModal}
               modalDetails={passwordModalDetatils}
               copyButtonText={copyButtonText}
               passwordRef={passwordRef}
               handleCopyText={handleCopyText}
            />
         )}
         {isEditUserModalOpen && (
            <EditUserModal
               isOpen={isEditUserModalOpen}
               handleClose={handleEditModalClose}
               title="Edit User"
               userDetails={selectedUser}
               onSubmit={onSubmit}
               validationErrors={validationErrors}
               handleDropdownChange={handleDropdownChange}
               handleToggleChange={handleToggleChange}
               handleDateChange={handleDateChange}
            />
         )}
      </>
   )
}

export default Users
